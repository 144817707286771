<div class="overlay-inner-wrapper">
  <header class="header-2 scroll-x no-scroll-y">
    <div class="branding" style="min-width: 0;">
      <span class="title">{{'Wartung' | mrTranslate}}</span>
    </div>
    <div class="header-actions clr-justify-content-between" style="flex-shrink: 1;">
      <div class="d-f row clr-flex-grow-1 clr-align-self-center h-auto"
        style="column-gap: 15px;">
        <div class="width100 h-auto flex-1-1-0" style="min-width: 100px;">
          <clr-date-container *ngIf="!isReadOnly; else readonlyDate" class="mt-0">
            <label class="headerFont" for="date">
              {{'Datum' | mrTranslate}}
            </label>
            <input type="date" clrDate
              class="headerFont"
              [ngModel]="wartungsdate | localeDate"
              (ngModelChange)="wartungsdate = localeDate.tosql($event)"
              [ngModelOptions]="{updateOn: 'blur', standalone: true}"
            />
          </clr-date-container>
          <ng-template #readonlyDate>
            <clr-input-container class="mt-0">
              <label class="headerFont">
                {{'Datum' | mrTranslate}}
              </label>
              <input clrInput type="text" readonly
                class="headerFont ellipsis"
                [ngModel]="wartungsdate | localeDate"
                [ngModelOptions]="{standalone: true}"
              />
            </clr-input-container>
          </ng-template>
        </div>

        <div *ngIf="bemerkung || !isReadOnly" class="flex-2-2-0 width100 h-auto" style="min-width: 100px; max-width: 600px;">
          <clr-datalist-container *ngIf="!isReadOnly; else readonlyBemerkung" class="mt-0">
            <label class="clr-col-12 clr-col-md-12 headerFont">
              {{'Bemerkung' | mrTranslate}}
            </label>
            <input clrDatalistInput type="search"
              class="clr-col-12 clr-col-md-12 w-100-p headerFont"
              [(ngModel)]="bemerkung"
              [ngModelOptions]="{standalone: true}"
              [disabled]="isReadOnly"
            />
            <datalist>
              <option *ngFor="let wert of bemerkungen$ | async" [value]="wert"></option>
            </datalist>
          </clr-datalist-container>
          <ng-template #readonlyBemerkung>
            <clr-input-container class="mt-0">
              <label class="headerFont">
                {{'Bemerkung' | mrTranslate}}
              </label>
              <input clrInput type="text" readonly
                class="headerFont ellipsis"
                [ngModel]="bemerkung"
                [ngModelOptions]="{standalone: true}"
              />
            </clr-input-container>
          </ng-template>
        </div>

        <div class="width100 h-auto flex-1-1-0" style="min-width: 75px;">
          <clr-input-container class="mt-0">
            <label class="headerFont">
              {{'Zuständig' | mrTranslate}}
            </label>
            <input clrInput type="text" readonly 
              class="headerFont ellipsis"
              [ngModel]="objekt?.verantwortlich"
              [ngModelOptions]="{standalone: true}"
            />
          </clr-input-container>
        </div>
        <div class="width100 h-auto flex-1-1-0" style="min-width: 75px;">
          <clr-input-container class="mt-0">
            <label class="headerFont">
              {{'Maske' | mrTranslate}}
            </label>
            <input clrInput type="text" readonly 
              class="headerFont ellipsis"
              [ngModel]="objekt?.maske"
              [ngModelOptions]="{standalone: true}"
            />
          </clr-input-container>
        </div>
      </div>
      <div class="header-nav">
        <a class="nav-link nav-icon" href="javascript:void(0);" 
          (click)="detailPanel.open(); isDetailPanelOpened = true">
          <clr-icon shape="tree-view" [title]="'Historie öffnen' | mrTranslate"></clr-icon>
        </a>
        <a class="nav-link nav-icon" href="javascript:void(0);" (click)="close()">
          <clr-icon shape="times" size="22" [title]="'Schliessen' | mrTranslate"></clr-icon>
        </a>
      </div>
    </div>
  </header>
  <clr-tabs class="h-60 margin-10-lr padding-12-b">
    <clr-tab class="wartung">
      <button clrTabLink>
        {{ "Maske" | mrTranslate }} - {{ objekt.maske }}
      </button>
      <clr-tab-content>
        <ng-container *ngIf="{
          groupedWartung: wartung$ | async,
          mehListe: meh$ | async,
          requiredFK: requiredFK$ | async
        } as async">
          <div *ngIf="async.groupedWartung" class="h-100 scroll padding-6-t">
            <ng-container *ngTemplateOutlet="isReadOnly ? readonly : form; context: { async }"></ng-container>
            <ng-template #readonly let-wartung="async.groupedWartung">
              <clr-accordion id="wartungAccordion" clrAccordionMultiPanel="true" class="nm">
                <clr-accordion-panel *ngFor="let header of wartung | keyvalue: noSort; let isFirst = first"
                  [clrAccordionPanelOpen]="isFirst">
                  <clr-accordion-title class="basealign">{{ header.value.name }}
                    <button *ngIf="header.value.helper?.HelpBildBase64 || header.value.helper?.HelpBeschreibung"
                      type="button"
                      class="btn btn-sm btn-icon btn-link"
                      [title]="'Beschreibung' | mrTranslate"
                      (click)="$event.stopPropagation(); openHelper(header.value.helper)">
                      <clr-icon shape="help"></clr-icon>
                    </button>
                  </clr-accordion-title>
                  <clr-accordion-content [clrIfExpanded]="isFirst" class="wartungArtItem w-100-p">
                    <table class="table margin-0-t table-compact nowrap w-100-p" style="table-layout: fixed;">
                      <thead style="position: sticky; top: -7px; z-index: 3;">
                        <tr>
                          <th class="left ellipsis" style="width: 30%;">{{'Wartungsart' | mrTranslate}}</th>
                          <th>{{'i.O.' | mrTranslate}}</th>
                          <th class="ellipsis">{{'Messwert' | mrTranslate}}</th>
                          <th class="left ellipsis">{{'Maßnahmen' | mrTranslate}}</th>
                          <th>{{'FK' | mrTranslate}}</th>
                          <th class="ellipsis">{{'Umfang' | mrTranslate}}</th>
                          <th class="ellipsis">{{'Mengeneinheiten' | mrTranslate}}</th>
                          <th *ngIf="showHilfeColumn" class="ellipsis">{{'Hilfe' | mrTranslate}}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let row of header.value.children">
                          <td class="left ellipsis text-wrap">{{ row.artName }}</td>
                          <td>
                            <ng-container [ngSwitch]="row.iO">
                              <clr-icon *ngSwitchCase="1" shape="success-standard" class="is-solid" style="fill: #2EC0FF;"></clr-icon>
                              <clr-icon *ngSwitchCase="0" shape="circle"></clr-icon>
                            </ng-container>
                          </td>
                          <td>{{ row.Messwert || '-' }}</td>
                          <td class="left ellipsis">{{ row.arbeitName }}</td>
                          <td>{{ row.FK }}</td>
                          <td>{{ row.RevUmfang }}</td>
                          <td>{{ row.RevME }}</td>
                          <td *ngIf="showHilfeColumn">
                            <button *ngIf="row.HelpBildBase64 || row.HelpBeschreibung"
                              type="button"
                              class="btn btn-sm btn-icon btn-link centred"
                              [title]="'Beschreibung' | mrTranslate"
                              (click)="openHelper(row)">
                              <clr-icon shape="help"></clr-icon>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </clr-accordion-content>
                </clr-accordion-panel>
              </clr-accordion>
            </ng-template>
            <ng-template #form let-wartung="async.groupedWartung"
              let-mehListe="async.mehListe" let-requiredFK="async.requiredFK">
              <form clrForm #f="ngForm" ngForm (ngSubmit)="saveIfValid(f)"
                clrLabelSize="12" class="width100 w-100-p">
                <clr-accordion id="wartungAccordion" clrAccordionMultiPanel="true">
                  <clr-accordion-panel *ngFor="let header of wartung | keyvalue: noSort; let isFirst = first"
                    [clrAccordionPanelOpen]="isFirst" #headerBlock="ngModelGroup"
                    ngModelGroup="{{header.key}}-{{header.value.name}}">
                    <clr-accordion-title class="basealign">{{ header.value.name }}
                      <button *ngIf="header.value.helper?.HelpBildBase64 || header.value.helper?.HelpBeschreibung"
                        type="button"
                        class="btn btn-sm btn-icon btn-link"
                        [title]="'Beschreibung' | mrTranslate"
                        (click)="$event.stopPropagation(); openHelper(header.value.helper)">
                        <clr-icon shape="help"></clr-icon>
                      </button>
                    </clr-accordion-title>
                    <clr-accordion-content [clrIfExpanded]="isFirst" class="wartungArtItem">
                      <table class="table margin-0-t table-compact nowrap" style="table-layout: fixed;">
                        <thead style="position: sticky; top: -7px; z-index: 3;">
                          <tr>
                            <th class="left ellipsis" style="width: 30%;">{{'Wartungsart' | mrTranslate}}</th>
                            <th class="left">
                              <span>{{'i.O.' | mrTranslate}}</span>
                              <button class="btn btn-sm btn-icon btn-link clr-col-auto"
                                style="margin: -5px 0;" tabindex="-1"
                                type="button" [title]="'Alle aus-/abwählen' | mrTranslate"
                                (click)="iOAlle.checked = !iOAlle.checked; checkAll(iOAlle.checked, headerBlock.control)">
                                <input type="checkbox" #iOAlle hidden [checked]="header.value.children | check: allSelected"/>
                                <clr-icon [attr.shape]="iOAlle.checked ? 'circle' : 'success-standard'"></clr-icon>
                              </button>
                            </th>
                            <th class="ellipsis">{{'Messwert' | mrTranslate}}</th>
                            <th class="left ellipsis">{{'Maßnahmen' | mrTranslate}}</th>
                            <th>{{'FK' | mrTranslate}}</th>
                            <th class="ellipsis">{{'Umfang' | mrTranslate}}</th>
                            <th class="left">
                              <div class="d-f row">
                                <div class="clr-col flex-1-1-0 ellipsis">
                                  {{'Mengeneinheiten' | mrTranslate}}
                                </div>
                                <button class="btn btn-sm btn-icon btn-link clr-col-auto"
                                  style="margin: -5px 0;" tabindex="-1"
                                  type="button" [title]="'Tabelle verwalten' | mrTranslate"
                                  (click)="openTabelle()">
                                  <clr-icon shape="pencil"></clr-icon>
                                </button>
                              </div>
                            </th>
                            <th *ngIf="showHilfeColumn" class="ellipsis">{{'Hilfe' | mrTranslate}}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let row of header.value.children" #artGroup="ngModelGroup" ngModelGroup="{{row.artID}}">
                            <input type="number" name="ID" [ngModel]="row.ID" hidden>
                            <input type="text" name="wartungsart" [ngModel]="row.artName" hidden>
                            <input type="number" name="omazsid" [ngModel]="row.OMAZSID" hidden>
                            <ng-container *ngIf="row.artTyp != 0">
                              <td class="left ellipsis text-wrap">{{ row.artName }}</td>
                              <td class="centred" style="max-width: 50px;">
                                <clr-checkbox-container class="center">
                                  <label hidden></label>
                                  <clr-checkbox-wrapper>
                                    <input type="checkbox" clrCheckbox #iO="ngModel" [(ngModel)]="row.iO" name="i.O."
                                      (ngModelChange)="setValidations(iO.value, artGroup.control, requiredFK)" />
                                  </clr-checkbox-wrapper>
                                </clr-checkbox-container>
                              </td>
                              <td [ngSwitch]="row.artTyp" class="width100" style="max-width: 100px;">
                                <ng-container *ngSwitchCase="1"> - </ng-container>
                                <ng-container *ngSwitchCase="2">
                                  <clr-input-container>
                                    <input clrInput name="messwert" [(ngModel)]="row.Messwert"
                                      type="number" step="0.1" required />
                                    <clr-control-error *clrIfError="'required'">
                                      {{"Erforderlich" | mrTranslate}}
                                    </clr-control-error>
                                  </clr-input-container>
                                </ng-container>
                              </td>
                              <td class="left width100">
                                <clr-datalist-container>
                                  <input class="clr-col-12" clrDatalistInput type="search"
                                    name="massnahmen" [(ngModel)]="row.arbeitName"
                                    (ngModelChange)="createArbeitAndFindFK(row)"
                                    [ngModelOptions]="{ updateOn: 'blur' }"/>
                                  <datalist>
                                    <option *ngFor="let wert of row.zuordnungen?.arbeiten | keyvalue" [value]="wert.key">
                                      {{wert.key}}
                                    </option>
                                  </datalist>
                                  <clr-control-error *clrIfError="'required'">
                                    {{"Erforderlich" | mrTranslate}}
                                  </clr-control-error>
                                </clr-datalist-container>
                                <input type="number" name="arbeitID" [ngModel]="row.arbeitID" hidden>
                              </td>
                              <td class="width100" style="max-width: 75px;">
                                <clr-select-container>
                                  <select clrSelect name="fk" [(ngModel)]="row.FK">
                                    <option *ngIf="!requiredFK" [value]="null"></option>
                                    <option *ngFor="let fk of rangeFK" [value]="fk">{{fk}}</option>
                                  </select>
                                  <clr-control-error *clrIfError="'required'">
                                    {{"Erforderlich" | mrTranslate}}
                                  </clr-control-error>
                                </clr-select-container>
                              </td>
                              <td class="width100" style="max-width: 125px;">
                                <clr-input-container>
                                  <input clrInput name="umfang" [(ngModel)]="row.RevUmfang" type="number" step="0.1" min="0" />
                                </clr-input-container>
                              </td>
                              <td class="width100" style="max-width: 100px;">
                                <clr-select-container>
                                  <select clrSelect name="meh" [(ngModel)]="row.RevME">
                                    <option *ngFor="let meh of async.mehListe" [value]="meh">{{meh}}</option>
                                  </select>
                                </clr-select-container>
                              </td>
                              <td *ngIf="showHilfeColumn">
                                <button *ngIf="row.HelpBildBase64 || row.HelpBeschreibung"
                                  type="button"
                                  class="btn btn-sm btn-icon btn-link centred"
                                  [title]="'Beschreibung' | mrTranslate"
                                  (click)="openHelper(row)">
                                  <clr-icon shape="help"></clr-icon>
                                </button>
                              </td>
                            </ng-container>
                          </tr>
                        </tbody>
                      </table>
                    </clr-accordion-content>
                  </clr-accordion-panel>
                </clr-accordion>
                <button type="submit" class="btn btn-primary margin-24-l">
                  {{'Speichern' | mrTranslate}}
                </button>
              </form>
            </ng-template>
            <div *ngIf="!(async.groupedWartung | keyvalue)?.length">
              {{ 'Keine Datei vorhanden' | mrTranslate }}
           </div>
          </div>
        </ng-container>      
      </clr-tab-content>
    </clr-tab>
    <clr-tab class="zaehler">
      <button clrTabLink>
        {{ "Zähler" | mrTranslate }}
      </button>
        <clr-tab-content>
          <div class="h-100 scroll">
            <!-- <app-zaehlertab></app-zaehlertab> -->
          </div>
        </clr-tab-content>
    </clr-tab>
    <clr-tab *ngIf="objekt.inspektionID">
      <button clrTabLink>
        {{ "Mangel" | mrTranslate }} / {{ "Zustand" | mrTranslate }}
      </button>
      <clr-tab-content>
        <div class="grid w-100-p h-100 scroll" id="maengel-grid-container" 
          *ngIf="maengel$ | async as table">
          <button class="btn btn-icon" style="justify-self: start;"
            (click)="createMangel(table)" [disabled]="isReadOnly || !objekt.inspektionID">
            <clr-icon shape="plus-circle" [title]="'Mangel anlegen' | mrTranslate"></clr-icon>
          </button>
          <div class="w-100-p scroll border-table margin-6-t">
            <table class="table margin-0-t table-compact nowrap">
              <thead style="position: sticky; top: 0; z-index: 3;">
                <tr>
                  <th>{{ 'Log' | mrTranslate }}</th>
                  <th>{{ 'Medien' | mrTranslate }}</th>
                  <ng-container *ngFor="let column of table.columns">
                    <th *ngIf="!(column.id | hideIDColumns: ['bildpfad', 'rueckbilder'])" class="left">
                      {{ column.id | titlecase | mrTranslate}}
                    </th>
                  </ng-container>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let row of table.rows">
                  <td>
                    <a href="javascript:void(0);" (click)="mangelLog = row.OMAZSID">
                      <clr-icon shape="view-list" size="20"></clr-icon>
                    </a>
                  </td>
                  <td>
                    <a href="javascript:void(0);" 
                      (click)="mangelMedia = media.openMangelMedia(row.bildpfad, row.rueckbilder)"
                      [class.deactivate]="!row.bildpfad && !row.rueckbilder">
                      <clr-icon shape="image-gallery" size="20"></clr-icon>
                    </a>
                  </td>
                  <ng-container *ngFor="let column of table.columns">
                    <td *ngIf="!(column.id | hideIDColumns: ['bildpfad', 'rueckbilder'])" class="left"
                      [innerHTML]="row[column.id] | tablePrettyPrint">
                    </td>
                  </ng-container>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </clr-tab-content>
    </clr-tab>
  </clr-tabs>
</div>
<clr-side-panel id="detailViewSidePanel" #detailPanel>
  <div class="side-panel-body">
    <app-detailview *ngIf="isDetailPanelOpened && objekt?.OSTAMMID" [ostammid]="objekt.OSTAMMID"
      (enlarge)="handleDetailviewEnlarge($event, detailPanel)">
    </app-detailview>
  </div>
</clr-side-panel>

<app-hilfe-beschreibung-modal [readonly]="true"></app-hilfe-beschreibung-modal>
<app-basedatamodal></app-basedatamodal>
<app-mangel-log-modal *ngIf="mangelLog" [(open)]="mangelLog"></app-mangel-log-modal>
<app-mediaviewmodal *ngIf="mangelMedia"
  (onClose)="mangelMedia = undefined"
  [srcs]="mangelMedia"
  [getMediaFromServerAll]="media.getApiFunctionMedia"
  [getBigFileFnc]="media.getApiFunctionMediaBig">
</app-mediaviewmodal>

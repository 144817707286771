<div id="anlagenWrapper" class="clr-row nxm h-100">

  <div #datagridwrapper class="h-100" [ngStyle]="{
      width: !showdetailview || hidedetailview ? '100%' : split50 ? '50%' : '66.66%',
    }">

   <div class="clr-row">
      <div class="clr-col" style="align-items: center; column-gap: 0.2rem; display: inline-flex;">

        <ng-container *ngIf="token.rights.progVersion == '45'">
          <ng-container class="UserManagement" *ngIf="token.rights.status >= 3 || token.rights.dev == true && useFAT">
            <clr-dropdown  id="PropertiesDropdown" style="display: inline-flex; align-items: center;">
              <button class="btn btn-primary btn-sm" clrDropdownTrigger><clr-icon shape="plus"></clr-icon> {{ "Neu" | mrTranslate }} &nbsp; <clr-icon shape="caret down"></clr-icon></button>
              <clr-dropdown-menu  *clrIfOpen>

                <ng-container *ngIf="useFAT">
                  <a (click)="openFATObjectcreation()" clrDropdownItem>{{ "Werksabnahme - neues Objekt erstellen" | mrTranslate }}</a>
                </ng-container>

                <ng-container *ngIf="sotyp?.length">
                  <clr-dropdown >
                    <a clrDropdownTrigger>{{ "Stammdaten erstellen" | mrTranslate }}</a>
                    <clr-dropdown-menu  *clrIfOpen>
                      <ng-container *ngFor="let typ of sotyp">
                        <a (click)="openNeuStamm(typ)" clrDropdownItem><clr-icon style="margin-left: -10px; color: #000;" class="customIcon" [attr.shape]="'objekttyp' + typ.symbol" size="22"></clr-icon>  {{ typ.bezeichnung }}</a>
                      </ng-container>

                      <ng-container *ngIf="typid == 1 || typid == 3">
                        <div class="dropdown-divider" role="separator"></div>
                        <a (click)="copyViewStamm(ostammid)" clrDropdownItem>{{ "Stammdaten kopieren" | mrTranslate }}</a>
                      </ng-container>


                    </clr-dropdown-menu>
                  </clr-dropdown>
                </ng-container>
              </clr-dropdown-menu>
            </clr-dropdown>
          </ng-container>
        </ng-container>


        <ng-container *ngIf="table && table['rows'].length > 0">
          <button class="btn btn-primary btn-sm" (click)="deleteAllFilters()">{{'Alle Filter zurücksetzen' | mrTranslate}}</button>
        </ng-container>

      <!-- btn-primary btn-sm -->
      </div>

      <div class="clr-col" style="display: flex; justify-content: flex-end;">

        <ng-container class="UserManagement" *ngIf="token.rights.status >= 2 || token.rights.dev == true">
          <button class="btn btn-sm btn-outline" style="min-width: 42px;" (click)="getSammelausdruck()">
            <clr-icon size="20" shape="view-list" class="cursor-pointer"></clr-icon>
          </button>
          <button class="btn btn-sm btn-outline" style="min-width: 42px;" (click)="getExcelList()">
            <clr-icon size="20" shape="excel" class="cursor-pointer"></clr-icon>
          </button>
        </ng-container>

        <button class="btn btn-sm btn-outline" style="min-width: 42px;" (click)="getQRCode()">
          <clr-icon size="20" shape="qr-code" class="cursor-pointer"></clr-icon>
        </button>

        <button class="btn btn-sm btn-outline" style="min-width: 42px;" (click)="reloadAnlagen()">
          <clr-icon shape="refresh"></clr-icon>
        </button>
      </div>
    </div>


    <clr-datagrid *ngIf="table && table['rows'].length > 0" [(clrDgSingleSelected)]="ostammid" class="datagrid-compact" [attr.data-test-id]="'datagrid-anlagen'"> <!--  [clrDgRowSelection]="true"> -->
      <clr-dg-column *ngFor="let column of table['columns']" [clrDgField]="column.id"
        [attr.id]="'datagrid-anlagen-col-' + normalizeId(column.id)"
        [attr.data-test-id]="'datagrid-anlagen-col-' + column.id"
        [style.min-width.px]="(column.id == 'KAV' || column.id == 'status') ? 100
        : iconColumns.indexOf(column.id.toLowerCase()) == -1 ? (column.id.length * 7.5) + 50
        : 85">
        <clr-dg-filter [attr.data-test-id]="'datagrid-anlagen-col-filter-' + column.id">
          <app-column-filter tableName="anlagen" [column]="column" [tableUniqueColumnValues]="tableUniqueColumnValues" [uniqueColumnValues]="getUniqueValuesForColumn(tableUniqueColumnValues, column.id)" (changes)="onFilterChange($event)"/>
        </clr-dg-filter>

        <ng-container *clrDgHideableColumn="{
          hidden: column.id | hideIDColumns : ['SPID', 'bild']
        }">
          {{
          column.id == "KAV"
          ? table["rows"][0]?.KAV &&
          table["rows"][0].KAV.toString().indexOf("%") != -1
          ? "SAX"
          : column.id
          : (column.id | titlecase | mrUnitText )
          }}
        </ng-container>
      </clr-dg-column>

      <clr-dg-row *clrDgItems="let row of data" [clrDgItem]="row.OSTAMMID">

        <clr-dg-action-overflow>
          <button *ngIf="hasMap && !isRep" class="action-item" (click)="openObjectInGISMap(row.OSTAMMID)">
            <clr-icon size="18" shape="map-marker"></clr-icon>&nbsp;{{ "in GIS Map öffnen" | mrTranslate }}
          </button>
          <button *ngIf="token.alv === true" [disabled]="row.ALV === true" class="action-item"
            (click)="set_ALV_Checked(row.LPID, 1)">
            {{ "durch ALV bestätigen" | mrTranslate }}
          </button>
          <button *ngIf="token.ebl === true" [disabled]="row.ALV === false" class="action-item"
            (click)="set_ALV_Checked(row.LPID, 2)">
            {{ "durch EBL bestätigen" | mrTranslate }}
          </button>
          <!-- <button *ngIf="row.otypid === 3" class="action-item" (click)="openFATPruefungcreation(row.ostammid)">{{'Werksabnahme durchführen' | mrTranslate}}</button> -->

          <!-- <button *ngIf="STDEL" class="action-item" (click)="deleteStamm(row)"><clr-icon size="18" shape="trash"></clr-icon>&nbsp;{{'Stamm löschen' | mrTranslate}}</button> -->
          <!-- <button *ngIf="PRDEL" class="action-item" (click)="deletePruef(row)"><clr-icon size="18" shape="eraser"></clr-icon>&nbsp;{{'Prüfung löschen' | mrTranslate}}</button> -->

          <ng-container *ngIf="useFAT">
            <div class="UserManagement" *ngIf="token.rights.status >= 3 || token.rights.dev == true && row.otypid == 1">
              <button class="action-item" (click)="openFATPruefungcreation(row.OSTAMMID)"><clr-icon size="18" shape="factory"></clr-icon>&nbsp;{{'Werksabnahme durchführen' | mrTranslate}}</button>
              <ng-container *ngIf="row.LPID != 0">
                <button class="action-item" (click)="openFATPruefungcreationEdit(row.OSTAMMID, row.LPID)"><clr-icon size="18" shape="note"></clr-icon>&nbsp;{{'Werksabnahme bearbeiten' | mrTranslate}}</button>
              </ng-container>
            </div>
          </ng-container>

          <div *ngIf="row['SI'] == 1">
            <button class="action-item" (click)="openSonderinspektion(row)"><clr-icon size="18" shape="warning-standard"></clr-icon>&nbsp;{{'Sonderinspektion öffnen' | mrTranslate}}</button>
          </div>

        </clr-dg-action-overflow>


        <clr-dg-cell *ngFor="let column of table['columns']; let j = index"
          [style.min-width.px]="(column.id == 'KAV' || column.id == 'status') ? 100
          : iconColumns.indexOf(column.id.toLowerCase()) == -1 ? (column.id.length * 7.5) + 50
          : 85">

          <ng-container *ngIf="iconColumns.indexOf(column.id.toLowerCase()) == -1; else iconcell">
            <ng-container *ngIf="column.id == 'KAV'; else elseTemplate">
              <clr-dg-cell [style.min-width.px]="100" [title]="row[column.id] | tablePrettyPrint">
                <span>
                  <clr-icon *ngIf="row[column.id] != null" shape="circle" class="is-solid" [ngStyle]="{ color: row['kavcolor'] }" size="16"></clr-icon>&nbsp;
                  {{ row[column.id] | number:'.0-3' }}
                </span>
              </clr-dg-cell>
            </ng-container>
            <ng-template #elseTemplate>
              <clr-dg-cell [style.min-width.px]="column.id.length * 7.5 + 50" class="ellipsis"
                [innerHTML]="row[column.id] | tablePrettyPrint : { column: { id: column.id } }" [title]="row[column.id] ">
              </clr-dg-cell>
            </ng-template>
          </ng-container>

          <ng-template #iconcell>
            <clr-dg-cell style="color: #000;" [style.min-width.px]="85">
              <ng-container [ngSwitch]="column.id.toLowerCase()">
                <clr-icon *ngSwitchCase="'typ'" class="customIcon" [attr.shape]="
                    row['typ_icon']
                      ? 'objekttyp' + row['typ_icon']
                      : 'unknown-status'" size="28">
                </clr-icon>
                <clr-icon *ngSwitchCase="'rep'" class="customIcon"
                  [ngStyle]="{ color: row['Rep'] == 0 ? 'green' : 'red' }"
                  [attr.shape]="row['Rep'] == 0 ? 'shield-check' : 'shield-x'" size="25"></clr-icon>
                <clr-icon *ngSwitchCase="'sp'" class="customIcon" [attr.shape]="
                    row['SP'] && row['SP'] > 0
                      ? row['Richtung'] > 0 ? 'richtung' + row['Richtung'].toString()
                      : 'gesperrt'
                      : 'blank'" size="28">
                </clr-icon>
                <clr-icon *ngSwitchCase="'lf'" class="customIcon" [attr.shape]="
                    row['LF'] && row['LF'] > 0
                      ? 'lastelle' + (row['LF'] / 10).toString().replace('.', '_')
                      : 'blank'" size="28">
                </clr-icon>
                <clr-icon *ngSwitchCase="'st'" class="customIcon iconcolor" [attr.shape]="
                    row['ST'] == 1 ? 'exclamation-triangle' : 'blank'" size="28">
                </clr-icon>
                <clr-icon *ngSwitchCase="'si'" class="customIcon"
                  [ngStyle]="{ color: 'red' }"
                  [attr.shape]="row['SI'] == 1 ? 'warning-standard' : 'blank'" size="28">
                </clr-icon>
              </ng-container>
            </clr-dg-cell>
          </ng-template>
        </clr-dg-cell>
      </clr-dg-row>

      <clr-dg-footer>
        <clr-dg-column-toggle></clr-dg-column-toggle>
        <clr-dg-pagination class="dg-pagination" #pagination [clrDgPageSize]="pageCount" [clrDgPage]="page">
          <clr-dg-page-size [clrPageSizeOptions]="[10, 20, 50, 100]">{{'Zeilen pro Seite' | mrTranslate}}
          </clr-dg-page-size>
          {{ pagination.firstItem + 1 }} - {{ pagination.lastItem + 1 }} {{ "von" | mrTranslate }} {{ pagination.totalItems }} {{ "Zeilen" | mrTranslate }}
        </clr-dg-pagination>
      </clr-dg-footer>
    </clr-datagrid>

  </div>
  <div #dvwrapper id="wrapperdetailview" *ngIf="showdetailview && ostammid"
    [ngClass]="{ sidenavenlarged, split50, hidedetailview }">
    <app-detailview [ostammid]="_ostammid" [progressbarenlarged]="progressbarenlarged" [expanded]="sidenavenlarged" [hidden]="hidedetailview"
      [split50]="split50" (enlarge)="enlargeSidenav($event)" (enlargePB)="enlargeProgressbar(true)"
      (progressData)="showProgressData($event)" (indexScrub)="handleScrub($event)" (reload)="reloadAnlagen($event)"
      #detailView></app-detailview>
  </div>
  <!-- <div #dvwrapper id="wrapperdetailview" *ngIf="showdetailview"
  [ngClass]="{ sidenavenlarged: sidenavenlarged, split50: split50 }">
  <app-detailview [ostammid]="_ostammid" [viewMode]=1 [expanded]="sidenavenlarged" [split50]="split50" (enlarge)="enlargeSidenav($event)"
  (indexScrub)="handleScrub($event)" #detailView></app-detailview>
</div> -->
</div>

<div id="progresstrigger" *ngIf="showprogressbar" [ngClass]="{ bottom: !progressbarenlarged }">
  <div class="wrap" (click)="enlargeProgressbar()">
    <clr-icon [ngStyle]="{
        transform: progressbarenlarged ? 'rotate(180deg)' : 'rotate(0deg)'
      }" shape="caret" class="expandProgressbarButton cursor-pointer"></clr-icon>
  </div>
</div>
<footer *ngIf="showprogressbar && progressbarenlarged" id="wrapperprogressbars" [ngClass]="
    !progressbarenlarged && !sidenavenlarged
      ? split50
        ? 'shorter'
        : 'short'
      : ''
  " class="clr-row nxm clr-align-items-center">
  <app-progressbar [dataIn]="progressbars" [indexScrub]="indexScrub" (enlargeProgressbar)="enlargeProgressbar(true)"
    (openDialogAt)="openOverlay($event)" style="display: inline-block; width: calc(100% - 35px)"></app-progressbar>
</footer>

<clr-modal *ngIf="isALVModalVisible" [(clrModalOpen)]="isALVModalVisible" [clrModalClosable]="true">
  <h3 class="modal-title">{{ alvHeader }}</h3>
  <div class="modal-body">
    <form clrForm clrLayout="horizontal">
      <clr-radio-container class="margin-0-t">
        <label style="display: table">{{ "Zustand" | mrTranslate }}</label>
        <clr-radio-wrapper>
          <input type="radio" clrRadio name="radio-alv" [value]="3" #alvstatus />
          <label>{{ "Gut" | mrTranslate }}</label>
        </clr-radio-wrapper>
        <clr-radio-wrapper>
          <input type="radio" clrRadio name="radio-alv" [value]="2" #alvstatus />
          <label>{{ "Medium" | mrTranslate }}</label>
        </clr-radio-wrapper>
        <clr-radio-wrapper>
          <input type="radio" clrRadio name="radio-alv" [value]="1" #alvstatus />
          <label>{{ "Schlecht" | mrTranslate }}</label>
        </clr-radio-wrapper>
        <clr-radio-wrapper>
          <input type="radio" clrRadio name="radio-alv" [value]="0" checked="true" #alvstatus />
          <label>{{ "Undefiniert" | mrTranslate }}</label>
        </clr-radio-wrapper>
      </clr-radio-container>

      <clr-textarea-container>
        <label>{{ "Bemerkung" | mrTranslate }}</label>
        <textarea clrTextarea name="bemerkung" #alvbem></textarea>
      </clr-textarea-container>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="updateALVStatus()">{{'OK' | mrTranslate}}</button>
    <button type="button" class="btn" (click)="isALVModalVisible = false">{{'Abbrechen' | mrTranslate}}</button>
  </div>
</clr-modal>



<clr-modal [(clrModalOpen)]="copyStammModal" [clrModalSize]="'md'"
  [clrModalClosable]="true">
  <h3 class="modal-title">{{ "Stammdaten kopieren" | mrTranslate }}</h3>
  <div class="modal-body">
    <form clrForm clrLayout="horizontal" style="margin-top:-25px">
      <!-- <p>{{ "Bitte geben Sie ihre Email unter ein und sie erhalten eine Bestätigungscode per Email mit dem Sie ihr Passwort zurücksetzten können." | mrTranslate }}</p> -->
      <clr-input-container>
        <label class="clr-col-md-4">{{ "Neue Bezeichnung" | mrTranslate }}</label>
        <input class="clr-col-md-8" style="width: 300px;" clrInput id="exforgotemail" type="email" #newStammBez /><br />
      </clr-input-container>
      <br/>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="copyStamm()">{{ "OK" | mrTranslate }}</button>
    <button type="button" class="btn" (click)="copyStammModal = false">{{ "Abbrechen" | mrTranslate }}</button>
  </div>
</clr-modal>
<clr-modal [(clrModalOpen)]="qrScanModal" [clrModalSize]="'md'"
  [clrModalClosable]="true">
  <h3 class="modal-title">{{ "QR-Code Scannen" | mrTranslate }}</h3>
  <div class="modal-body">
    <video id="video"></video>
  </div>
  <div class="modal-footer">
    <!-- <button type="button" class="btn btn-primary" (click)="copyStamm()">{{ "OK" | mrTranslate }}</button> -->
    <button type="button" class="btn" (click)="qrScanModal = false">{{ "Abbrechen" | mrTranslate }}</button>
  </div>
</clr-modal>
